import { render, staticRenderFns } from "./Delivery.vue?vue&type=template&id=dd04b6b8&scoped=true&"
import script from "./Delivery.vue?vue&type=script&lang=js&"
export * from "./Delivery.vue?vue&type=script&lang=js&"
import style0 from "./Delivery.vue?vue&type=style&index=0&id=dd04b6b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd04b6b8",
  null
  
)

export default component.exports